var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c('v-card',{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('app.route.sections'),"back-button":""}},[_c('v-text-field',{staticClass:"d-none d-md-flex mx-1",staticStyle:{"width":"0"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":"","small":"","color":"green"},on:{"click":function($event){return _vm.$$router.push({
            name: 'manager.store.section',
            params: { sectionUuid: 'new', storeId: _vm.$route.params.storeId },
          })}}},[_c('v-icon',[_vm._v("mdi-folder-plus")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.new")))])],1),_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.editItem()}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.manageCategories()}}},[_c('v-icon',[_vm._v("mdi-view-list")]),_c('span',[_vm._v(_vm._s(_vm.$t("app.route.categories")))])],1),_c('v-divider',{attrs:{"vertical":""}}),(_vm.$hasRole('SUPER-ADMIN') || _vm.$hasRole('ADMIN') || _vm.$hasRole('STORE-MANAGER'))?_c('v-btn',{attrs:{"text":"","small":"","color":"red","disabled":!_vm.selected},on:{"click":function($event){return _vm.confirmDelete()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])],1):_vm._e()],1),_c('v-card-text',{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(124))},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getSections,"item-key":"id","search":_vm.search,"show-select":"","single-select":"","value":_vm.selection,"loading":_vm.loading,"loading-text":_vm.$t('messages.loading')},on:{"item-selected":_vm.selectionChanged,"click:row":_vm.selectionChanged},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
          var item = ref.item;
          var isSelected = ref.isSelected;
          var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"on-icon":"mdi-checkbox-marked-circle","off-icon":"mdi-power-off","value":isSelected,"color":"primary","light":"","ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{staticClass:"my-1 mr-1",staticStyle:{"border":"1px solid #eee"},attrs:{"size":"32"}},[(!!item.image)?_c('v-img',{attrs:{"src":_vm.getImage(item.image)}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.name))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }